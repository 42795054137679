
import { defineComponent } from 'vue';
import {GEM_FARM_PROG_ID} from "../../../../src";
export default defineComponent({
  setup() {
    return {
      GEM_FARM_PROG_ID,
    };
  },
});
