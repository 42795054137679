
import { defineComponent, onMounted, ref, watch } from 'vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import { initGemFarm } from '@/common/gem-farm';
import { PublicKey } from '@solana/web3.js';
import {findFarmTreasuryPDA} from "../../../../../src";

export default defineComponent({
  props: {
    farm: String,
  },
  setup(props, ctx) {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();

    let gf: any;
    watch([wallet, cluster], async () => {
      gf = await initGemFarm(getConnection(), getWallet()!);
    });

    //need an onmounted hook because this component isn't yet mounted when wallet/cluster are set
    onMounted(async () => {
      if (getWallet() && getConnection()) {
        gf = await initGemFarm(getConnection(), getWallet()!);
        await getTresauryBalance();
      }
    });

    watch(
      () => props.farm,
      async () => {
        await getTresauryBalance();
      }
    );

    // --------------------------------------- payout
    const destination = ref<string>();
    const lamports = ref<string>();
    const balance = ref<string>();

    const payoutFromTreasury = async () => {
      await gf.treasuryPayoutWallet(
        new PublicKey(props.farm!),
        new PublicKey(destination.value!),
        lamports.value!
      );
      await getTresauryBalance();
    };

    const getTresauryBalance = async () => {
      const [treasury] = await findFarmTreasuryPDA(new PublicKey(props.farm!));
      console.log('treasury found:', treasury.toBase58());
      balance.value = await gf.getBalance(treasury);
    };

    return {
      balance,
      destination,
      lamports,
      payoutFromTreasury,
    };
  },
});
