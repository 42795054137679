import { PublicKey } from '@solana/web3.js';

export * from './gem-bank';
export * from './gem-farm';
export * from './gem-common';

export const GEM_BANK_PROG_ID = new PublicKey(
  '4eoek7eR5WwYSpjTDMR87CuVtaS4zvGzXAAEg66E5oQy'
);
export const GEM_FARM_PROG_ID = new PublicKey(
  'Gx8TktfeeSb1vqotJS3UF6b5W1duxmnwgvm7Auhu3rXE'
);
